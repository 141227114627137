
import {map} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from './api.service';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class AccountService {
  @Output() accountDataUpdate: EventEmitter<any> = new EventEmitter();
  public localUserDataName = 'sleepAppAccountData';
  public accountData: any;

  constructor(private api: ApiService) {
    this.accountData = this.getLocalUserData();
  }

  getAccountData() {
    const queryParams = {};
    const url = `/users/me`;

    return this.api.get(url, queryParams).pipe(map(res => {
      this.saveLocalUserData(res);

      return res;
    }));
  }

  updateAccountData(data: any) {
    const url = `/users/${data.id}`;

    return this.api.patch(url, data).pipe(map(
      res => {
        return res;
      }));
  }

  updateAccountEmail(data: any) {
    const url = `/users/${data.id}/change_email`;

    return this.api.patch(url, data).pipe(map(
      res => {
        return res;
      }));
  }

  getLocalUserData() {
    try {
      return JSON.parse(localStorage.getItem(this.localUserDataName));
    } catch (err) {
      return {};
    }
  }

  saveLocalUserData(accData) {
    this.accountData = accData;
    localStorage.setItem(this.localUserDataName, JSON.stringify(accData));
    this.accountDataUpdate.emit(this.accountData);
  }

  removeLocalUserData() {
    localStorage.removeItem(this.localUserDataName);
    this.accountData = null;
  }

}

@Injectable()
export class AccountRouteResolver implements Resolve<any> {
  constructor(private account: AccountService) {
  }

  resolve(): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {
      this.account.getAccountData()
        .subscribe(resolve, reject);
    });
  }
}
