import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-user-trial',
  styleUrls: ['user-trial.component.scss'],
  templateUrl: './user-trial.component.html'
})

export class UserTrialComponent {
  formData: FormGroup | any = {};
  defaultData: any = {
    dialogTitle: ''
  };

  constructor(
    public dialogRef: MatDialogRef<UserTrialComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.defaultData = {
      ...this.defaultData,
      ...this.data
    };

    this.formData = fb.group({
      dateTo: data.dateTo ? data.dateTo : moment()
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSubmit(): void {
    if (this.formData.invalid) {
      return;
    }

    const dPipe = new DatePipe('en-Us');
    const data = this.formData.value;
    const response = {
      id: this.defaultData.id,
      trialEndedAt: dPipe.transform(data.dateTo, 'yyyy-MM-dd')
    };

    this.dialogRef.close(response);
  }
}
