import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParameterCodec,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
​
class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
​
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
​
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
​
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
​
@Injectable()
export class EncodeHttpParamsInterceptor implements HttpInterceptor {
  constructor() {}
​
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'GET') {
      return next.handle(
        req.clone({ params: new HttpParams({ encoder: new CustomEncoder(), fromString: req.params.toString() }) })
      );
    }
    return next.handle(req);
  }
}
