import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SupportComponent } from './support.component';
import { SupportService } from './support.service';
import { SupportCategoriesService } from './categories/support-categories.service';
import { SupportCategoryItemComponent } from './categories/support-category-item/support-category-item.component';
import { SupportCategoriesComponent } from './categories/support-categories.component';
import { SupportFaqService } from './faq/support-faq.service';
import { SupportFaqComponent } from './faq/support-faq.component';
import { SupportFaqItemComponent } from './faq/support-faq-item/support-faq-item.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    SupportComponent,
    SupportCategoriesComponent,
    SupportCategoryItemComponent,
    SupportFaqComponent,
    SupportFaqItemComponent,
  ],
  entryComponents: [
    SupportCategoryItemComponent,
    SupportFaqItemComponent
  ],
  providers: [
    SupportService,
    SupportCategoriesService,
    SupportFaqService
  ]
})
export class SupportModule {
}
