import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../shared/dialog/dialog.component';

@Injectable()
export class DialogService {
  private config: any = {
    width: '300px',
    height: 'auto',
    backdropClass: '',
    hasBackdrop: true,
    panelClass: 'app-dialog',
    position: {
      top: '',
      right: '',
      bottom: '',
      left: ''
    },
    data: {}
  };

  constructor(private dialog: MatDialog) {
  }

  public open(config) {
    config = {
      ...this.config,
      ...config
    };
    const dialogRef = this.dialog.open(DialogComponent, config);

    return dialogRef.afterClosed();
  }
}
