import {Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthService} from '../../services/auth.service';
import {AccountService} from '../../services/account.service';
import {Router} from '@angular/router';

interface MenuItem {
  link: string;
  title: string;
  isExternal?: true;
  isOpened?: boolean;
  iconClass: string;
  children?: MenuItemChild[];
}

interface MenuItemChild {
  title: string;
  link: string;
  iconClass: string;
}

@Component({
  selector: 'app-side-nav',
  templateUrl: 'side-nav.component.html',
  styleUrls: ['side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  isSidenavOpened: boolean = true;
  navigationLinksModel: MenuItem[] = [
    {
      title: 'Users management',
      link: '/users',
      iconClass: 'fa-users',
    },
    {
      title: 'Groups management',
      link: '/groups',
      iconClass: 'fa-users',
    },
    {
      title: 'Audios management',
      link: '/sounds',
      iconClass: 'fa-music',
    },
    {
      title: 'Categories management',
      link: '/categories',
      iconClass: 'fa-list-alt',
    },

    {
      title: 'Settings',
      link: '/close-account-reasons',
      iconClass: 'fa-list-alt',
      children: [
        {
          title: 'Close account reasons',
          link: '/close-account-reasons',
          iconClass: 'fa-user-times',
        },
        {
          title: 'Account',
          link: '/account',
          iconClass: 'fa-user',
          // isDefault: true,
        },
        {
          title: 'Common',
          link: '/settings',
          iconClass: 'fa-cog',
          // isDefault: true,
        },
      ]
    },
    // {
    //   title: 'Support',
    //   link: '/support',
    //   iconClass: 'fa-question',
    // },
  ];
  navigationLinks: Array<any> = [];

  mobileQuery;
  tabletQuery;
  _mobileQueryListener: () => void;
  _tabletQueryListener: () => void;

  constructor(public auth: AuthService,
              private account: AccountService,
              private changeDetectorRef: ChangeDetectorRef,
              private media: MediaMatcher,
              private router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 959px)');
    this.tabletQuery = media.matchMedia('(min-width: 960px)');
    this.isSidenavOpened = this.tabletQuery.matches;

    this._mobileQueryListener = () => {
      this.isSidenavOpened = this.mobileQuery.matches;
      changeDetectorRef.detectChanges();
    };

    this._tabletQueryListener = () => {
      this.isSidenavOpened = this.tabletQuery.matches;
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.tabletQuery.addListener(this._tabletQueryListener);
    this.navigationLinks = this.filterNavLinks(this.navigationLinksModel);
  }

  ngOnInit() {
  }

  filterNavLinks(links: Array<any>) {
    return links;
  }


  onMenuOpen(item: MenuItem): void {
    this.navigationLinksModel.forEach(link => {
      if (typeof link.isOpened === 'boolean') {
        link.isOpened = false;
      }
    });

    item.isOpened = true;
    this.router.navigateByUrl(item.link);
  }

  onMenuClose(item: MenuItem): void {
    item.isOpened = false;
  }

  isActive(path: string): boolean {
    return this.router.url.includes(path);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.tabletQuery.removeListener(this._tabletQueryListener);
  }
}
