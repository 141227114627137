import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-confirmation-reset-password',
  templateUrl: 'confirmation-reset-password.component.html',
  styleUrls: ['confirmation-reset-password.component.scss']
})
export class ConfirmationResetPasswordComponent implements OnInit {
  invalidCredentials: boolean = false;
  confirmationToken: string;
  confirmResetPassForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    repeatPassword: new FormControl('', [Validators.required]),
  });

  constructor(private auth: AuthService,
              private notification: NotificationService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    const params = this.route.snapshot.queryParams;

    this.confirmationToken = params.token;
  }

  getErrorMessage(fieldName = '') {
    const field = this.confirmResetPassForm.controls[fieldName];
    if (field && field.invalid) {
      if (field.errors.required) {
        return 'Field is required';
      }
    }

    return '';
  }

  matchPasswords() {
    const pass = this.confirmResetPassForm.controls['password'].value;
    const repeatPass = this.confirmResetPassForm.controls['repeatPassword'].value;

    if (pass !== '') {
      const n = pass.localeCompare(repeatPass);
      return n === 0;
    }
  }

  submit() {
    if (this.matchPasswords()) {
      if (this.confirmationToken !== '') {
        this.auth.confirmationResetPassword(this.confirmationToken, this.confirmResetPassForm.controls['password'].value)
          .subscribe();
      } else {
        this.notification.error('Error, please turn back to your email and follow link again.');
      }
    } else {
      this.notification.error('Your passwords are not equal. Please, check your passwords.');
    }
  }
}
