import { Component } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { SoundsService } from './sounds.service';
import { MatDialog } from '@angular/material';
import { SoundItemComponent } from './sound-item/sound-item.component';
import { GridViewClass } from '../../shared/grid-view/grid-view.class';
import { NotificationService } from '../../services/notification.service';
import { DateFilterComponent } from '../date-filter/date-filter.component';

@Component({
  selector: 'app-applications',
  templateUrl: './sounds.component.html',
  styleUrls: ['./sounds.component.scss']
})
export class SoundsComponent extends GridViewClass<SoundsComponent> {
  public columnNames: Array<string> = [
    'id',
    'coverPath',
    'title',
    'category',
    'createdAt',
    'duration',
    'description',
    'availableForDemo',
    'active',
    'avgRating',
    'numListeners',
    'totalNumPlays',
    'totalTimeListened',
    'actions',
  ];

  public savedData: Params = {};

  constructor(dialogService: DialogService,
              router: Router,
              private dialog: MatDialog,
              private soundsService: SoundsService,
              private notification: NotificationService) {
    super(dialogService, router);
  }

  getData() {
    this.initDefaultOrder();

    const params = {
      ...this.queryParams
    };
    this.soundsService.getItems(params).subscribe(data => {
      super.setData(data);
    });
  }

  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'createdAt|desc';
    }
  }

  openModal(data: any = {}) {
    const dialogRef = this.dialog.open(SoundItemComponent, { width: '500px', data, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      if (res.id) {
        delete res.accept;
        this.soundsService.updateItem(res)
          .subscribe(() => {
            this.getData();
          });
      } else {
        this.soundsService.createItem(res)
          .subscribe(() => {
            this.getData();
          });
      }
    });
  }

  toggleActiveState(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to ' + (itemData.active ? 'deactivate' : 'activate') + ' this audio?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.soundsService.updateItem({
          id: itemData.id,
          active: !itemData.active
        }).subscribe(() => {
          this.getData();
        });
      }
    });
  }

  deleteItem(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this audio?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.soundsService.removeItem(itemData.id)
          .subscribe(_ => {
            this.getData();
          });
      }
    });
  }

  dateFilter(fieldName) {
    const dialogRef = this.dialog.open(DateFilterComponent, {
      data: {
        dateFrom: this.savedData[fieldName] && this.savedData[fieldName].dateFrom,
        dateTo: this.savedData[fieldName] && this.savedData[fieldName].dateTo,
      },
      width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        if (res.reset) {
          delete this.savedData[fieldName];
          this.resetDateFilter(fieldName);
          return;
        }

        this.savedData[fieldName] = {
          dateFrom: res.dateFrom,
          dateTo: res.dateTo
        };

        this.applyDateFilter(
          fieldName,
          res.dateFromFormatted,
          res.dateToFormatted
        );
      });
  }

  export(id?: number) {
    this.soundsService.exportItems(id, this.queryParams).subscribe(res => {
      this.notification.success(`Audio${id ? '' : 's'} successfully exported.`);
    });
  }

  routeLink($event: MouseEvent, link: string[]) {
    $event.stopPropagation();
    this.router.navigate(link);
  }
}
