import {Component} from '@angular/core';

@Component({
    selector: 'app-basic',
    templateUrl: 'basic.component.html',
})
export class BasicLayoutComponent {

    constructor() {
    }
}
