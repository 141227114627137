import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ApiClientService } from '../../../services/api-client.service';

@Injectable()
export class GroupUsersService extends ApiClientService {
  urlPath: string = '/groups';

  constructor(api: ApiService) {
    super(api);
  }

  setUrlPath(id: string) {
    this.urlPath = `/groups/${id}/users`;
  }
}
