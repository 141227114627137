import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesStateService {
  uploading = [];
  filesCounting = {};
  fileCountingName = null;

  isUploadingFiles(): boolean {
    return !!(this.uploading.length);
  }

  clearUploadingFiles() {
    this.uploading = [];
  }

  putUploadingFile(fileName) {
    if (this.uploading[fileName] === undefined) {
      this.uploading.push(fileName);
    }
  }

  popUploadingFile(fileName) {
    this.uploading = this.uploading.filter(item => item !== fileName);
  }

  putCountFiles(fileName) {
    if (!(fileName in this.filesCounting)) {
      this.filesCounting = {
        ...this.filesCounting,
        [fileName]: 1
      };
    } else {
      this.filesCounting[fileName]++;
    }
  }

  getCountFiles(fileName) {
    if ((fileName in this.filesCounting)) {
      return this.filesCounting[fileName];
    }

    return 0;
  }

  genFileName(fileName) {
    const newFileName = fileName.split(/[\.](?<ext>[^\.]+)$/);
    const countFiles = this.getCountFiles(fileName);

    this.fileCountingName
      = newFileName[0]
      + (countFiles > 1 ? '_' + countFiles : '')
      + '.'
      + (newFileName[1] !== undefined ? newFileName[1] : '')
    ;

    return this.fileCountingName;
  }
}
