import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-date-filter',
  styleUrls: ['date-filter.component.scss'],
  templateUrl: './date-filter.component.html',
})

export class DateFilterComponent {
  formData: FormGroup | any = {};
  defaultData: any = {
    dialogTitle: ''
  };

  constructor(public dialogRef: MatDialogRef<DateFilterComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    const dateFrom = moment();
    dateFrom.subtract(30, 'days');

    this.formData = fb.group({
      dateFrom: data.dateFrom ? data.dateFrom : dateFrom,
      dateTo: data.dateTo ? data.dateTo : moment()
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onReset(): void {
    this.dialogRef.close({reset: true});
  }

  onSubmit(): void {
    if (this.formData.invalid) return;

    const data = this.formData.value;
    const dPipe = new DatePipe('en-Us');

    data.dateFrom.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    data.dateTo.set({hour: 23, minute: 59, second: 59, millisecond: 999});

    data.dateFromFormatted = dPipe.transform(data.dateFrom, 'yyyy-MM-ddTHH:mm:ss');
    data.dateToFormatted = dPipe.transform(data.dateTo, 'yyyy-MM-ddTHH:mm:ss');

    this.dialogRef.close(data);
  }
}
