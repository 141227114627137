import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ApiClientService } from '../../services/api-client.service';

@Injectable()
export class CategoriesService extends ApiClientService {
  urlPath: string = `/categories`;

  constructor(api: ApiService) {
    super(api);
  }
}
