import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mSec'
})
export class MillisecPipe implements PipeTransform {

  constructor() {
  }

  transform(seconds: number) {
    const multiplyValue = 1000;
    const resultString = seconds
      ? `${Math.round(seconds * multiplyValue)} ms`
      : '';

    return resultString;
  }
}
