import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { GroupUsersService } from './group-users.service';
import { MatDialog } from '@angular/material';
import { UserItemComponent } from '../../users/user-item/user-item.component';
import { GridViewClass } from '../../../shared/grid-view/grid-view.class';
import { AccountService } from '../../../services/account.service';
import { NotificationService } from '../../../services/notification.service';
import { DateFilterComponent } from '../../date-filter/date-filter.component';
import { UserTrialComponent } from '../../users/user-trial/user-trial.component';
import { GroupImportUsersComponent } from '../group-import-users/group-import-users.component';
import { UsersService } from '../../users/users.service';
import { GroupImportErrorsListComponent } from '../group-import-errors-list/group-import-errors-list.component';

@Component({
  selector: 'app-reports',
  templateUrl: './group-users.component.html',
  styleUrls: ['./group-users.component.scss']
})
export class GroupUsersComponent extends GridViewClass<GroupUsersComponent> {
  public columnNames: Array<string> = [
    'id',
    'firstName',
    'lastName',
    'email',
    'createdAt',
    'lastAction',
    'os',
    'enabled',
    'subscriptionExpiredAt',
    'trialEndedAt',
    'actions'
  ];

  public savedData: Params = {};
  public now = Date.now();

  constructor(
    protected dialogService: DialogService,
    protected router: Router,
    private dialog: MatDialog,
    private groupUsersService: GroupUsersService,
    private usersService: UsersService,
    public account: AccountService,
    private notification: NotificationService,
    private route: ActivatedRoute
  ) {
    super(dialogService, router);
  }

  getData() {
    this.initDefaultOrder();

    const customParams = {
      ...this.queryParams
    };

    this.route.params.subscribe((params: Params) => {
      this.groupUsersService.setUrlPath(params.id);

      this.groupUsersService.getItems(customParams).subscribe(data => {
        super.setData(data);
      });
    });
  }


  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'createdAt|desc';
    }
  }

  deleteUser(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this user?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.groupUsersService.removeItem(itemData.id)
          .subscribe(_ => {
            this.getData();
          });
      }
    });
  }

  openEditModal(data: any = {}) {
    const dialogRef = this.dialog.open(UserItemComponent, {
      data,
      width: '500px',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.usersService.updateItem(res)
          .subscribe(() => {
            this.getData();
          });
      });
  }

  addUser(data: any = {}) {
    const dialogRef = this.dialog.open(UserItemComponent, { width: '500px', data, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      delete res.accept;
      this.groupUsersService.addUserToGroup(res)
        .subscribe(() => {
          this.getData();
        });
    });
  }

  openEditFreeTrialModal(data: any = {}) {
    const dialogRef = this.dialog.open(UserTrialComponent, {
      data,
      width: '500px',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.usersService.updateItemTrial(res)
          .subscribe(() => {
            this.getData();
          });
      });
  }

  toggleActiveState(itemData) {
    this.dialogService.open({
      data: {
        title: `Are you sure you want to ${!itemData.enabled ? 'activate' : 'deactivate'} this user?`
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.usersService.blockItem(
          itemData.id,
          !itemData.enabled
        ).subscribe(() => {
          this.getData();
        });
      }
    });
  }

  dateFilter(fieldName) {
    const dialogRef = this.dialog.open(DateFilterComponent, {
      data: {
        dateFrom: this.savedData[fieldName] && this.savedData[fieldName].dateFrom,
        dateTo: this.savedData[fieldName] && this.savedData[fieldName].dateTo,
      },
      width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        if (res.reset) {
          delete this.savedData[fieldName];
          this.resetDateFilter(fieldName);
          return;
        }

        this.savedData[fieldName] = {
          dateFrom: res.dateFrom,
          dateTo: res.dateTo
        };

        this.applyDateFilter(
          fieldName,
          res.dateFromFormatted,
          res.dateToFormatted
        );
      });
  }

  import(data: any = {}) {
      const dialogRef = this.dialog.open(
        GroupImportUsersComponent,
        { width: '500px', data, disableClose: true }
      );

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.getData();

          if (res.result.errors.alreadyPresent.emails.length) {
            const errorsList = res.result.errors;

            this.dialog.open(
              GroupImportErrorsListComponent,
              { width: '500px', data: { errorsList }, disableClose: true }
            );
          }

          return;
        }

      return;
    });
  }

  public checkTrialEnded(user): boolean {
    const trialEndedAt = new Date(user.trialEndedAt).getTime();

    return this.now <= trialEndedAt;
  }

  public checkSubscriptionExpired(user): boolean {
    const subscriptionExpiredAt = new Date(user.subscriptionExpiredAt).getTime();

    return this.now <= subscriptionExpiredAt;
  }

  public addFreeTrialForAllUsers(data: any = {}): void {
    const dialogRef = this.dialog.open(UserTrialComponent, {
      data,
      width: '500px',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.groupUsersService.updateGroupUsersTrial(res)
          .subscribe(() => {
            this.getData();
          });
      });
  }

  public export(id?: number) {
    this.groupUsersService.exportItems(id, this.queryParams).subscribe(res => {
      this.notification.success(`User${id ? '' : 's'} successfully exported.`);
    });
  }
}
