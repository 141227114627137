import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ApiClientService } from '../../../services/api-client.service';

@Injectable()
export class SupportCategoriesService extends ApiClientService {
  urlPath: string = `/support_categories`;

  constructor(api: ApiService) {
    super(api);
  }
}
