import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { UploadFilesStateService } from '../../../services/upload-files-state.service';

@Component({
  selector: 'app-user-trial',
  templateUrl: './group-import-users.component.html',
  styleUrls: ['group-import-users.scss']
})

export class GroupImportUsersComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GroupImportUsersComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private uploadFilesState: UploadFilesStateService
  ) {}

  ngOnInit() {
    this.uploadFilesState.clearUploadingFiles();
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  public loadFile(event): void {
    this.dialogRef.close(event);
  }
}
