import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['dialog.component.scss']
})
export class DialogComponent {
  defaultData: any = {
    title: '',
    content: '',
    cancelText: 'No',
    submitText: 'Yes'
  };

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.defaultData = {
      ...this.defaultData,
      ...data
    };
  }

  onCancel(): void {
    this.data.accept = false;
    this.dialogRef.close(this.data);
  }

  onSubmit(): void {
    this.data.accept = true;
    this.dialogRef.close(this.data);
  }

}
