import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AccountService } from './account.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService) {
  }

  canActivate(snapShot: ActivatedRouteSnapshot) {
    const isTokenExist = !!this.auth.getToken();
    if (isTokenExist) return true;
    this.auth.router.navigate(['sign-in']);
    return false;
  }

}

@Injectable()
export class NavGuard implements CanActivate {

  constructor(private account: AccountService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const isUserRole = this.account.accountData && this.account.accountData.role === 'user';

    if (isUserRole) {
      this.router.navigate(['account']);
      return false;
    }

    return true;
  }

}
