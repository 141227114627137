import { Component } from '@angular/core';
import {Params, Router} from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { CategoriesService } from './categories.service';
import { MatDialog } from '@angular/material';
import { CategoryItemComponent } from './category-item/category-item.component';
import { GridViewClass } from '../../shared/grid-view/grid-view.class';
import { NotificationService } from '../../services/notification.service';
import { DateFilterComponent } from '../date-filter/date-filter.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent extends GridViewClass<CategoriesComponent> {
  public columnNames: Array<string> = [
    'id',
    'name',
    'image',
    'icon',
    'createdAt',
    'description',
    'soundsCount',
    'soundsAllCount',
    'active',
    'actions',
  ];

  public savedData: Params = {};

  constructor(dialogService: DialogService,
              router: Router,
              private dialog: MatDialog,
              private categoriesService: CategoriesService,
              private notification: NotificationService) {
    super(dialogService, router);
  }

  getData() {
    this.initDefaultOrder();

    const params = {
      ...this.queryParams
    };
    this.categoriesService.getItems(params).subscribe(data => {
      super.setData(data);
    });
  }

  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'createdAt|desc';
    }
  }

  openModal(data: any = {}) {
    const dialogRef = this.dialog.open(CategoryItemComponent, { width: '500px', data, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      if (res.id) {
        delete res.accept;
        this.categoriesService.updateItem(res)
          .subscribe(() => {
            this.getData();
          });
      } else {
        this.categoriesService.createItem(res)
          .subscribe(() => {
            this.getData();
          });
      }
    });
  }

  toggleActiveState(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to ' + (itemData.active ? 'deactivate' : 'activate') + ' this category?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.categoriesService.updateItem({
          id: itemData.id,
          active: !itemData.active
        }).subscribe(() => {
          this.getData();
        });
      }
    });
  }

  deleteItem(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this category?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.categoriesService.removeItem(itemData.id)
          .subscribe(_ => {
            this.getData();
          });
      }
    });
  }

  dateFilter(fieldName) {
    const dialogRef = this.dialog.open(DateFilterComponent, {
      data: {
        dateFrom: this.savedData[fieldName] && this.savedData[fieldName].dateFrom,
        dateTo: this.savedData[fieldName] && this.savedData[fieldName].dateTo,
      },
      width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        if (res.reset) {
          delete this.savedData[fieldName];
          this.resetDateFilter(fieldName);
          return;
        }

        this.savedData[fieldName] = {
          dateFrom: res.dateFrom,
          dateTo: res.dateTo
        };

        this.applyDateFilter(
          fieldName,
          res.dateFromFormatted,
          res.dateToFormatted
        );
      });
  }

  export(id?: number) {
    this.categoriesService.exportItems(id, this.queryParams).subscribe(res => {
      this.notification.success(`Categor${id ? 'y' : 'ies'} successfully exported.`);
    });
  }
}
