import {Component} from '@angular/core';

@Component({
  selector: 'app-page',
  template: `<router-outlet></router-outlet>`
})

export class PageComponent {

}
