import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ApiClientService } from '../../../services/api-client.service';

@Injectable()
export class SupportFaqService extends ApiClientService {
  urlPath: string = `/support_faq`;

  constructor(api: ApiService) {
    super(api);
  }

  getItems(params = {}) {
    const queryParams = {
      'expand' : 'category',
      ...params
    };

    return super.getItems(queryParams);
  }
}
