import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor() {
  }

  transform(duration: number) {
    const seconds = Math.round(duration % 60);
    const minutes = Math.round((duration - seconds) / 60);

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}
