import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-support-category-item',
  styleUrls: ['support-category-item.component.scss'],
  templateUrl: './support-category-item.component.html'
})
export class SupportCategoryItemComponent {
  formData: FormGroup | any = {};
  defaultData: any = {
    dialogTitle: ''
  };

  constructor(public dialogRef: MatDialogRef<SupportCategoryItemComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.defaultData = {
      ...this.defaultData,
      ...this.data
    };
    this.formData = fb.group({
      id: this.data.id,
      name: this.data.name,
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSubmit(): void {
    if (this.formData.invalid) return;
    this.data = {...this.formData.value};
    this.dialogRef.close(this.data);
  }
}
