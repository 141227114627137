import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class AppDataService {
  public editorOptions = {
    lineNumbers: true,
    mode: 'htmlmixed',
    lineWrapping: true,
  };
  private url: string = `/app_data`;

  constructor(private api: ApiService) {
  }

  getAll() {

    return this.api.get(this.url);
  }

  getPrivacy() {

    return this.api.get(`${this.url}/privacy`);
  }

  updatePrivacy(data) {

    return this.api.patch(`${this.url}/privacy`, {data});
  }

  reformatCode(str) {
    const div = document.createElement('div');
    div.innerHTML = str.trim();

    return formatCode(div, 0).innerHTML.trim();
  }

  cleanPretty(text) {
    const withoutSpaces = text.replace(/\n+ {2,}/gm, '');
    return withoutSpaces.replace(/>\n+/gm, '>');
  }

}

function formatCode(node, level) {
  const indentBefore = new Array(level++ + 1).join('  '),
    indentAfter = new Array(level - 1).join('  ');
  let textNode;

  for (let i = 0; i < node.children.length; i++) {

    textNode = document.createTextNode('\n' + indentBefore);
    node.insertBefore(textNode, node.children[i]);

    formatCode(node.children[i], level);

    if (node.lastElementChild === node.children[i]) {
      textNode = document.createTextNode('\n' + indentAfter);
      node.appendChild(textNode);
    }
  }

  return node;
}
