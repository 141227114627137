import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  data;
  accountPasswordForm;
  accountEmailForm;

  constructor(public auth: AuthService,
              public account: AccountService,
              private notification: NotificationService) {
  }

  ngOnInit() {
    this.initFormData();
    this.initEmailFormData();
  }

  initFormData() {
    this.data = this.account.accountData;
    this.accountPasswordForm = new FormGroup({
      password: new FormControl(''),
      passwordRepeat: new FormControl('')
    });
  }

  initEmailFormData() {
    this.data = this.account.accountData;
    this.accountEmailForm = new FormGroup({
      email: new FormControl(this.data.email),
      password: new FormControl(''),
    });
  }

  submitPasswordForm() {
    if (this.accountPasswordForm.invalid) return;
    const firstPassword = this.accountPasswordForm.get('password').value.trim();
    const secondPassword = this.accountPasswordForm.get('passwordRepeat').value.trim();
    if (!firstPassword || !secondPassword) return;
    if (firstPassword !== secondPassword) {
      this.notification.warning('Passwords are not equal.');
    } else {
      this.account.updateAccountData({
        id: this.data.id,
        plainPassword: firstPassword
      }).subscribe(() => {
        this.accountPasswordForm.reset();
        this.notification.success('Password successfully updated.');
      });
    }
  }

  submitEmailForm() {
    if (this.accountEmailForm.invalid) return;
    const email = this.accountEmailForm.get('email').value.trim();
    const password = this.accountEmailForm.get('password').value.trim();
    if (!email || !password) return;
    this.account.updateAccountEmail({
      id: this.data.id,
      currentEmail: this.data.email,
      email: email,
      userPassword: password
    }).
    subscribe(() => {
      this.notification.success('Email successfully updated.');
      this.accountEmailForm.reset();

      this.account.accountData.email = email;
      this.initEmailFormData();
    });
  }
}
