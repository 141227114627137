import { NgModule } from '@angular/core';
import { ObjectFitDirective } from './object-fit.directive';

@NgModule({
  declarations: [
    ObjectFitDirective,
  ],
  exports: [
    ObjectFitDirective,
  ]
})

export class DirectivesModule {
}
