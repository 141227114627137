import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { filter, finalize, map } from 'rxjs/operators';
import { saveBlob } from '../misc/helper-functions';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export abstract class ApiClientService {
  protected abstract urlPath: string;

  protected constructor(protected api: ApiService) {
  }

  public getItems(params: any = {}) {
    const queryParams = {
      ...params
    };

    return this.api.get(this.urlPath, queryParams);
  }

  public getItem(id: any, params: any = {}) {
    const queryParams = {
      ...params
    };

    return this.api.get(`${this.urlPath}/${id}`, queryParams);
  }

  public createItem(data: any) {
    return this.api.post(this.urlPath, data);
  }

  public createUserWithGeneratedPassword(data: any) {
    return this.api.post(`${this.urlPath}/make`, data);
  }

  public updateItem(data: any) {
    return this.api.patch(`${this.urlPath}/${data.id}`, data);
  }

  public updateItemTrial(data: any) {
    const { id, trialEndedAt } = data;
    return this.api.post(`${this.urlPath}/${id}/trial`, { trialEndedAt });
  }

  public createOrUpdateItem(data: any) {
    return this.api.put(`${this.urlPath}`, data);
  }

  public blockItem(id: number, status: boolean) {
    return this.api.patch(`${this.urlPath}/${id}/block`, {enabled: status});
  }

  public removeItem(id: number) {
    return this.api.remove(`${this.urlPath}/${id}`);
  }

  public exportItems(id?: number, queryParams: any = {}) {
    const urlPath = id ? `/export${this.urlPath}/${id}` : `/export${this.urlPath}`;
    const fullUrl = `${environment.baseUrl}${urlPath}`;

    this.api.showSpinner();

    const params = new HttpParams({
      fromObject: queryParams
    });

    return this.api.http.request(
      new HttpRequest(
        'GET',
        fullUrl,
        {
          ...this.api.getOptions(params, true),
          responseType: 'blob',
          observe: 'response'
        },
      )
    ).pipe(
      filter((res: { type: number } | HttpResponse<any>) => res instanceof HttpResponse),
      map((res: HttpResponse<any>) => {
        const contentDisposition = res.headers.get('content-disposition');
        if (!contentDisposition) {
          return throwError('Header Content-Disposition is not accessible.');
        }
        let fileName = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
        fileName = (fileName || '').replace(/"/g, '');
        saveBlob(res.body, fileName);

        return { body: res.body, fileName };
      }),
      finalize(() => {
        this.api.spinner.hide();
      })
    );
  }

  public addUserToGroup(data: any) {
    return this.api.post(`${this.urlPath}`, data);
  }

  public updateGroupUsersTrial(data: any) {
    const { trialEndedAt } = data;
    return this.api.post(`${this.urlPath}/trial`, { trialEndedAt });
  }
}
