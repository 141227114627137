import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { SupportFaqService } from './support-faq.service';
import { GridViewClass } from '../../../shared/grid-view/grid-view.class';
import { MatDialog } from '@angular/material';
import { SupportFaqItemComponent } from './support-faq-item/support-faq-item.component';
import { SupportService } from '../support.service';

@Component({
  selector: 'app-support-faq',
  templateUrl: './support-faq.component.html',
  styleUrls: ['./support-faq.component.scss']
})
export class SupportFaqComponent extends GridViewClass<SupportFaqComponent> {
  public columnNames: Array<string> = [
    'question',
    'answer',
    'category',
    'actions'
  ];

  constructor(dialogService: DialogService,
              router: Router,
              private dialog: MatDialog,
              private supportFaqService: SupportFaqService,
              private supportService: SupportService) {
    super(dialogService, router);
  }

  getData() {
    const params = {
      ...this.queryParams
    };
    this.supportFaqService.getItems(params)
      .subscribe(data => {
        super.setData(data);
      });
  }


  deleteItem(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this word?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.supportFaqService.removeItem(itemData.id)
          .subscribe(_ => {
            if (this.dataSource.data.length < 2) {
              this.resetPaginator();
            }
            this.getData();
            this.supportService.blackListWordsChanged$.emit();
          });
      }
    });
  }

  openEditModal(data: any = {}) {
    const dialogRef = this.dialog.open(SupportFaqItemComponent, {
      data,
      width: '350px'
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) return;
        if (res.id) {
          this.supportFaqService.updateItem(res)
            .subscribe(() => {
              this.getData();
              this.supportService.blackListWordsChanged$.emit();
            });
        } else {
          this.supportFaqService.createItem(res)
            .subscribe(() => {
              this.getData();
              this.supportService.blackListWordsChanged$.emit();
            });
        }
      });
  }

}
