import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SupportService {
  blackListWordsChanged$: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

}
