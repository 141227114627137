import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ApiClientService } from '../../services/api-client.service';

@Injectable()
export class SoundsService extends ApiClientService {
  urlPath: string = `/sounds`;

  constructor(api: ApiService) {
    super(api);
  }

  getItems(params = {}) {
    const queryParams = {
      'order-by': 'title|asc',
      'expand': 'category',
      ...params
    };

    return super.getItems(queryParams);
  }
}
