import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-group-item',
  styleUrls: ['group-item.component.scss'],
  templateUrl: './group-item.component.html',
})

export class GroupItemComponent {
  formData: FormGroup | any = {};
  defaultData: any = {
    dialogTitle: ''
  };

  constructor(
    public dialogRef: MatDialogRef<GroupItemComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.defaultData = {
      ...this.defaultData,
      ...this.data
    };

    this.formData = fb.group({
      title: [
        this.data.title,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50)]
      ],
      description: [
        this.data.description,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(200)]
      ]
    });
  }

  get nameErrors() {
    return this.formData.get('title').errors;
  }

  get nameTouched() {
    return this.formData.get('title').touched;
  }

  get nameRequired() {
    return this.formData.get('title').errors.required;
  }

  get nameMinLength() {
    return this.formData.get('title').errors.minlength;
  }

  get nameMaxLength() {
    return this.formData.get('title').errors.maxlength;
  }

  get descriptionErrors() {
    return this.formData.get('description').errors;
  }

  get descriptionTouched() {
    return this.formData.get('description').touched;
  }

  get descriptionRequired() {
    return this.formData.get('description').errors.required;
  }

  get descriptionMinLength() {
    return this.formData.get('description').errors.minlength;
  }

  get descriptionMaxLength() {
    return this.formData.get('description').errors.maxlength;
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSubmit(): void {
    if (this.formData.invalid) {
      return;
    }

    this.data = {
      ...this.formData.value,
      id: this.defaultData.id
    };
    this.dialogRef.close(this.data);
  }
}
