import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { BrowserModule } from '@angular/platform-browser';

import { BasicLayoutComponent } from './basic/basic.component';
import { BlankLayoutComponent } from './blank/blank.component';
import { SideNavComponent } from '../shared/side-nav/side-nav.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BasicLayoutComponent,
    BlankLayoutComponent,
    SideNavComponent
  ],
  imports: [
    RouterModule,
    MaterialModule,
    BrowserModule,
    SharedModule
  ],
  exports: [
    BasicLayoutComponent,
    BlankLayoutComponent,
    SideNavComponent
  ],
})

export class LayoutsModule {
}
