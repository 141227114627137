import { Routes } from '@angular/router';
import { AuthGuard, NavGuard } from './services/guard.service';

import { BlankLayoutComponent } from './layouts/blank/blank.component';
import { BasicLayoutComponent } from './layouts/basic/basic.component';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { AccountComponent } from './views/account/account.component';
import { AccountRouteResolver } from './services/account.service';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ConfirmationResetPasswordComponent } from './views/confirmation-reset-password/confirmation-reset-password.component';
import { CategoriesComponent } from './views/categories/categories.component';
import { SoundsComponent } from './views/sounds/sounds.component';
import { UsersComponent } from './views/users/users.component';
import { EventsComponent } from './views/events/events.component';
import { SupportComponent } from './views/support/support.component';
import { CloseAccountReasonsComponent } from './views/close-account-reasons/close-account-reasons.component';
import { PageComponent } from './views/page.component';
import { SoundReportComponent } from './views/sound-report/sound-report.component';
import { SettingsComponent } from './views/settings/settings.component';
import { CategoryReportComponent } from './views/category-report/category-report.component';
import { GroupsComponent } from './views/groups/groups.component';
import { GroupUsersComponent } from './views/groups/group-users/group-users.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full'
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'sign-in',
        component: SignInComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'new-password',
        component: ConfirmationResetPasswordComponent
      },
    ]
  },
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'account',
        component: AccountComponent
      },
      {
        path: 'support',
        component: SupportComponent
      },
      {
        path: 'categories',
        component:  PageComponent,
        children: [
          {
            path: '',
            component: CategoriesComponent
          },
          {
            path: ':id/report',
            component: CategoryReportComponent
          }
        ]
      },
      {
        path: 'users',
        component:  PageComponent,
        children: [
          {
            path: '',
            component: UsersComponent
          },
          {
            path: ':id/sounds-history',
            component: EventsComponent
          }
        ]
      },
      {
        path: 'groups',
        component:  PageComponent,
        children: [
          {
            path: '',
            component: GroupsComponent
          },
          {
            path: ':id/users',
            component: GroupUsersComponent
          }
        ]
      },
      {
        path: 'sounds',
        component:  PageComponent,
        children: [
          {
            path: '',
            component: SoundsComponent
          },
          {
            path: ':id/history',
            component: EventsComponent
          },
          {
            path: ':id/report',
            component: SoundReportComponent
          }
        ]
      },
      {
        path: 'close-account-reasons',
        component: CloseAccountReasonsComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
    ],
    resolve: {
      account: AccountRouteResolver
    }
  },
  {
    path: '**',
    redirectTo: 'account'
  }
];
