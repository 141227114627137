import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { SupportCategoriesService } from './support-categories.service';
import { GridViewClass } from '../../../shared/grid-view/grid-view.class';
import { MatDialog } from '@angular/material';
import { SupportCategoryItemComponent } from './support-category-item/support-category-item.component';
import { SupportService } from '../support.service';

@Component({
  selector: 'app-support-categories',
  templateUrl: './support-categories.component.html',
  styleUrls: ['./support-categories.component.scss']
})
export class SupportCategoriesComponent extends GridViewClass<SupportCategoriesComponent> {
  public columnNames: Array<string> = [
    'name',
    'actions'
  ];

  constructor(dialogService: DialogService,
              router: Router,
              private dialog: MatDialog,
              private supportCategoriesService: SupportCategoriesService,
              private supportService: SupportService) {
    super(dialogService, router);
  }

  getData() {
    const params = {
      ...this.queryParams
    };
    this.supportCategoriesService.getItems(params)
      .subscribe(data => {
        super.setData(data);
      });
  }


  deleteItem(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this support category?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.supportCategoriesService.removeItem(itemData.id)
          .subscribe(_ => {
            if (this.dataSource.data.length < 2) {
              this.resetPaginator();
            }
            this.getData();
            this.supportService.blackListWordsChanged$.emit();
          });
      }
    });
  }

  openEditModal(data: any = {}) {
    const dialogRef = this.dialog.open(SupportCategoryItemComponent, {
      data,
      width: '350px'
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) return;
        if (res.id) {
          this.supportCategoriesService.updateItem(res)
            .subscribe(() => {
              this.getData();
              this.supportService.blackListWordsChanged$.emit();
            });
        } else {
          this.supportCategoriesService.createItem(res)
            .subscribe(() => {
              this.getData();
              this.supportService.blackListWordsChanged$.emit();
            });
        }
      });
  }

}
