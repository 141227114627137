import { throwError as observableThrowError, Observable } from 'rxjs';

import { finalize, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
  public localTokenName: string = 'sleepAppAuthToken';

  constructor(public http: HttpClient,
              public spinner: Ng4LoadingSpinnerService,
              private notification: NotificationService,
              private router: Router) {
  }

  getOptions(queryParams?, isForUpload: boolean = false) {
    const options: any = {};
    const token = this.getLocalToken();
    options.headers = new HttpHeaders();
    if (!isForUpload) {
      options.headers = options.headers.set('Content-Type', 'application/json');
    }
    if (token) {
      options.headers = options.headers.set('Authorization', `Bearer ${token}`);
    }
    if (queryParams) {
      return {
        ...options,
        params: queryParams
      };
    }

    return options;
  }

  public getGuestToken(): Observable<any> {
    this.showSpinner();
    const url = `${environment.oauthUrl}/token`;
    const guestTokenParams = {
      grant_type: 'client_credentials',
      client_id: environment.oauthClientId,
      client_secret: environment.oauthClientSecret,
    };

    return this.http.post<TokenType | any>(url, guestTokenParams, this.getOptions()).pipe(
      catchError(err => this.errorHandler(err)),
      finalize(() => this.spinner.hide()));
  }

  public getAuthToken(email: string, password: string): Observable<any> {
    this.showSpinner();
    const url = `${environment.oauthUrl}/token`;
    const authTokenParams = {
      grant_type: 'password',
      username: email,
      password: password,
      client_id: environment.oauthClientId,
      client_secret: environment.oauthClientSecret,
    };

    return this.http.post<TokenType | any>(url, authTokenParams, this.getOptions()).pipe(
      catchError(err => this.errorHandler(err)),
      finalize(() => this.spinner.hide()));
  }

  public refreshAuthToken(): Observable<any> {
    this.showSpinner();
    const url = `${environment.oauthUrl}/token`;
    const authTokenParams = {
      grant_type: 'refresh_token',
      client_id: environment.oauthClientId,
      client_secret: environment.oauthClientSecret,
    };

    return this.http.post<TokenType | any>(url, authTokenParams).pipe(
      catchError(err => this.errorHandler(err)),
      finalize(() => this.spinner.hide()));
  }

  public get(url: string,
             queryParams?: any,
             withSpinner = true,
             handleError = true): Observable<any> {
    if (withSpinner) {
      this.showSpinner();
    }
    const fullUrl = `${environment.baseUrl}${url}`;

    return this.http.get(fullUrl, this.getOptions(queryParams)).pipe(
      catchError(err => handleError ? this.errorHandler(err) : observableThrowError(err)),
      finalize(() => this.spinner.hide()));
  }

  public post(url: string, body: any = {}): Observable<any> {
    this.showSpinner();
    const fullUrl = `${environment.baseUrl}${url}`;
    if (body.id !== undefined) {
      delete body.id;
    }

    return this.http.post(fullUrl, body, this.getOptions()).pipe(
      catchError(err => this.errorHandler(err)),
      finalize(() => this.spinner.hide()));
  }

  public put(url: string, body: any = {}): Observable<any> {
    this.showSpinner();
    const fullUrl = `${environment.baseUrl}${url}`;
    if (body.id !== undefined) {
      delete body.id;
    }

    return this.http.put(fullUrl, body, this.getOptions()).pipe(
      catchError(err => this.errorHandler(err)),
      finalize(() => this.spinner.hide()));
  }

  public patch(url: string, body: any = {}): Observable<any> {
    this.showSpinner();
    const fullUrl = `${environment.baseUrl}${url}`;
    if (body.id !== undefined) {
      delete body.id;
    }

    return this.http.patch(fullUrl, body, this.getOptions()).pipe(
      catchError(err => this.errorHandler(err)),
      finalize(() => this.spinner.hide()));
  }

  public remove(url: string): Observable<any> {
    this.showSpinner();
    const fullUrl = `${environment.baseUrl}${url}`;

    return this.http.delete(fullUrl, this.getOptions()).pipe(
      catchError(err => this.errorHandler(err)),
      finalize(() => this.spinner.hide()));
  }

  getLocalToken() {

    return localStorage.getItem(this.localTokenName) || '';
  }

  showSpinner() {
    setTimeout(() => this.spinner.show(), 0);
  }

  errorHandler(err) {
    switch (true) {
      case err.status === 401: {
        this.notification.error(err.error.error_description);
        this.router.navigate(['sign-in']);
        break;
      }
      case !!(err.error && err.error.error_description): {
        this.notification.error(err.error.error_description);
        break;
      }
      case !!(err.error && err.error.message): {
        this.notification.error(err.error.message);
        break;
      }
      case !!(err.error && err.error.fields): {
        const fields = err.error.fields;
        this.showFieldErrors(fields);
        break;
      }
      case !!(err.error && err.error.errors): {
        const errors = err.error.errors;
        let delay = 0;
        errors.forEach(errItem => {
          setTimeout(() => {
            this.notification.error(errItem.description);
          }, delay);
          delay += 2000;
        });
        break;
      }
      default:
        this.notification.error('Something went wrong.');
    }

    return observableThrowError(err);
  }

  showFieldErrors(fields) {
    let delay = 0;
    Object.keys(fields).forEach(key => {
      const upperCaseName = key[0].toUpperCase() + key.slice(1);
      if (fields[key].errors) {
        setTimeout(() => {
          this.notification.error(`${upperCaseName}: ${fields[key].errors[0].description}`);
        }, delay);
        delay += 2000;
      } else if (fields[key].fields) {
        this.showFieldErrors(fields[key].fields);
      }
    });
  }
}

export interface TokenType {
  access_token: '';
  expires_in: null;
  token_type: '';
  scope: null;
  refresh_token: '';
}
