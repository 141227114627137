import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule} from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { routes } from './app.routes';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { AuthGuard, NavGuard } from './services/guard.service';
import { NotificationService } from './services/notification.service';
import { AccountRouteResolver, AccountService } from './services/account.service';
import { Constants } from './services/constants.service';

import { AppComponent } from './app.component';

import { LayoutsModule } from './layouts/layouts.module';
import { MaterialModule } from './material/material.module';
import { ViewsModule } from './views/views.module';
import { UploadService } from './services/upload.service';
import { EncodeHttpParamsInterceptor } from './interceptors/encode-http-params.interceptor';
import { UploadFilesStateService } from './services/upload-files-state.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LayoutsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    ViewsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    ApiService,
    AuthService,
    AuthGuard,
    NavGuard,
    Ng4LoadingSpinnerService,
    NotificationService,
    AccountRouteResolver,
    AccountService,
    UploadService,
    UploadFilesStateService,
    Constants,
    { provide: HTTP_INTERCEPTORS, useClass: EncodeHttpParamsInterceptor, multi: true }
  ]
})
export class AppModule {
}
