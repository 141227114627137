import { Component } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { GroupsService} from './groups.service';
import { MatDialog } from '@angular/material';
import { GridViewClass } from '../../shared/grid-view/grid-view.class';
import { AccountService } from '../../services/account.service';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { GroupItemComponent } from './group-item/group-item.component';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent extends GridViewClass<GroupsComponent> {
  public columnNames: Array<string> = [
    'id',
    'title',
    'description',
    'createdAt',
    'actions'
  ];

  public savedData: Params = {};

  constructor(
    protected dialogService: DialogService,
    protected router: Router,
    private dialog: MatDialog,
    private groupsService: GroupsService,
    public account: AccountService
  ) {
    super(dialogService, router);
  }

  getData() {
    this.initDefaultOrder();

    const params = {
      ...this.queryParams
    };

    this.groupsService.getItems(params).subscribe(data => {
      super.setData(data);
    });
  }

  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'createdAt|desc';
    }
  }

  deleteGroup(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this group?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        const { id } = itemData;
        this.groupsService.removeItem(itemData.id)
          .subscribe(_ => {
            this.getData();
          });
      }
    });
  }

  editGroup(data: any = {}) {
    const dialogRef = this.dialog.open(GroupItemComponent, {
      data,
      width: '500px',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.groupsService.updateItem(res)
          .subscribe(() => {
            this.getData();
          });
      });
  }

  addGroup(data: any = {}) {
    const dialogRef = this.dialog.open(GroupItemComponent, { width: '500px', data, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.groupsService.createItem(res)
        .subscribe(() => {
          this.getData();
        });
    });
  }


  dateFilter(fieldName) {
    const dialogRef = this.dialog.open(DateFilterComponent, {
      data: {
        dateFrom: this.savedData[fieldName] && this.savedData[fieldName].dateFrom,
        dateTo: this.savedData[fieldName] && this.savedData[fieldName].dateTo,
      },
      width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        if (res.reset) {
          delete this.savedData[fieldName];
          this.resetDateFilter(fieldName);
          return;
        }

        this.savedData[fieldName] = {
          dateFrom: res.dateFrom,
          dateTo: res.dateTo
        };

        this.applyDateFilter(
          fieldName,
          res.dateFromFormatted,
          res.dateToFormatted
        );
      });
  }
}
