
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { NotificationService } from './notification.service';
import { AccountService } from './account.service';

export interface SignInType {
  username: string;
  password: string;
}

@Injectable()
export class AuthService {

  constructor(public api: ApiService,
              public router: Router,
              private notification: NotificationService,
              private account: AccountService) {
  }

  signIn(data: SignInType) {

    return this.api.getAuthToken(data.username, data.password).pipe(map(
      res => {
        this.saveToken(res.access_token);
        this.router.navigate(['account'], {replaceUrl: true});
      }));
  }

  signOut() {
    this.removeToken();
    this.account.removeLocalUserData();
  }

  resetPassword(email: string) {
    const url = `/users/${email}/update-token`;

    return this.api.getGuestToken().pipe(map(tokenRes => {
      this.saveToken(tokenRes.access_token);

      const data = {
        transport: 'web:password-reset'
      };

      return this.api.post(url, data)
        .subscribe(res => {
          this.removeToken();
          this.notification.success('Reset password link has been sent to your email');
          this.router.navigate(['sign-in']);
          return res;
        }, () => this.removeToken());
    }));

  }

  confirmationResetPassword(confirmationToken: string, plainPassword: string) {
    const url = `/users/${confirmationToken}`;

    return this.api.getGuestToken().pipe(map(tokenRes => {
      this.saveToken(tokenRes.access_token);

      return this.api.patch(url, {plainPassword: plainPassword})
        .subscribe(() => {
          this.removeToken();
          this.router.navigate(['sign-in']);
        }, () => this.removeToken());
    }));
  }

  saveToken(token) {
    localStorage.setItem(this.api.localTokenName, token);
  }

  getToken() {

    return localStorage.getItem(this.api.localTokenName);
  }

  removeToken() {
    localStorage.removeItem(this.api.localTokenName);
  }
}
