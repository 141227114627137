import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SoundsService} from '../../sounds/sounds.service';

@Component({
  selector: 'app-category-item',
  styleUrls: ['category-item.component.scss'],
  templateUrl: './category-item.component.html'
})
export class CategoryItemComponent implements OnInit {
  formData: FormGroup | any = {};
  sounds: any[];
  defaultData: any = {
    dialogTitle: ''
  };
  selectedSounds = [];

  constructor(public dialogRef: MatDialogRef<CategoryItemComponent>,
              private fb: FormBuilder,
              private soundsService: SoundsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.defaultData = {
      ...this.defaultData,
      ...this.data,
    };
    this.formData = fb.group({
      id: this.data.id,
      name: this.data.name,
      description: this.data.description,
      sounds: this.fb.array([])
    });
  }

  ngOnInit() {
    this.soundsService.getItems().subscribe((data) => {
      this.sounds = data.entities;
      const sounds = <FormArray>this.formData.get('sounds') as FormArray;
      for (const item of this.sounds) {
        if (item.category.id === this.data.id) {
          this.selectedSounds.push(item.id);
          sounds.push(new FormControl(item.id));
        }
      }
    });
  }

  iconChanged(res) {
    this.defaultData.icon = res.result;
    this.formData.addControl('icon', new FormControl(''));
    this.formData.get('icon').setValue(res.result ? res.result.id : null);
  }

  imageChanged(res) {
    this.defaultData.image = res.result;
    this.formData.addControl('image', new FormControl(''));
    this.formData.get('image').setValue(res.result ? res.result.id : null);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onCheckboxChange(event) {
    const sounds = <FormArray>this.formData.get('sounds') as FormArray;

    if (event.checked) {
      sounds.push(new FormControl(event.source.value));
    } else {
      let i: number = 0;
      sounds.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.source.value) {
          sounds.removeAt(i);
          return;
        }
        i++;
      });
    }

  }

  onSubmit(): void {
    if (this.formData.invalid) return;
    this.data = {...this.formData.value};
    this.dialogRef.close(this.data);
  }


}
