import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { CloseAccountReasonsService } from './close-account-reasons.service';
import { MatDialog } from '@angular/material';
import { CloseAccountReasonItemComponent } from './close-account-reason-item/close-account-reason-item.component';
import { GridViewClass } from '../../shared/grid-view/grid-view.class';


@Component({
  selector: 'app-close-account-reasons',
  templateUrl: './close-account-reasons.component.html',
  styleUrls: ['./close-account-reasons.component.scss']
})
export class CloseAccountReasonsComponent extends GridViewClass<CloseAccountReasonsComponent> {
  public columnNames: Array<string> = [
    'title',
    'description',
    'actions',
  ];

  public queryParams: any = {
    'page': 1,
    'per-page': 100
  };

  constructor(dialogService: DialogService,
              router: Router,
              private dialog: MatDialog,
              private closeAccountReasonsService: CloseAccountReasonsService) {
    super(dialogService, router);
  }

  getData() {
    const params = {
      ...this.queryParams
    };
    this.closeAccountReasonsService.getItems(params).subscribe(data => {
      super.setData(data);
    });
  }

  deleteItem(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this close account reason?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.closeAccountReasonsService.removeItem(itemData.id)
          .subscribe(_ => {
            this.getData();
          });
      }
    });
  }

  openModal(data: any = {}) {
    const dialogRef = this.dialog.open(CloseAccountReasonItemComponent, {data, disableClose: true});
    dialogRef.afterClosed().subscribe(res => {
      if (!res) return;
      if (res.delete) {
        return this.closeAccountReasonsService.removeItem(res.id)
          .subscribe(() => {
            this.getData();
          });
      }
      if (res.id) {
        delete res.accept;
        this.closeAccountReasonsService.updateItem(res)
          .subscribe(() => {
            this.getData();
          });
      } else {
        this.closeAccountReasonsService.createItem(res)
          .subscribe(() => {
            this.getData();
          });
      }
    });
  }
}
