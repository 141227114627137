import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { EventsService } from './events.service';
import { MatDialog } from '@angular/material';
import { GridViewClass } from '../../shared/grid-view/grid-view.class';
import { AccountService } from '../../services/account.service';
import { NotificationService } from '../../services/notification.service';
import { DateFilterComponent } from '../date-filter/date-filter.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends GridViewClass<EventsComponent> {
  public columnNames: Array<string> = [
    'user.id',
    'user.firstName',
    'user.lastName',
    'user.email',
    'sound.id',
    'sound.title',
    'lastPlay'
  ];

  public savedData: Params = {};

  constructor(dialogService: DialogService,
              router: Router,
              private routes: ActivatedRoute,
              private dialog: MatDialog,
              private eventsService: EventsService,
              public account: AccountService,
              private notification: NotificationService) {
    super(dialogService, router);
  }

  getData() {
    this.initDefaultOrder();

    this.queryParams.expand = 'user,sound';
    this.queryParams.user = '>0';

    const id = (this.routes.params as Params).value.id;
    const prePath = (this.routes.parent.url as Params).value[0].path;

    if (id && prePath) {
      this.queryParams[prePath.slice(0, -1)] = id;
    }

    const params = {
      ...this.queryParams
    };

    this.eventsService.getItems(params).subscribe(data => {
      super.setData(data);
    });
  }

  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'lastPlay|desc';
    }
  }

  dateFilter(fieldName) {
    const dialogRef = this.dialog.open(DateFilterComponent, {
      data: {
        dateFrom: this.savedData[fieldName] && this.savedData[fieldName].dateFrom,
        dateTo: this.savedData[fieldName] && this.savedData[fieldName].dateTo,
      },
      width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        if (res.reset) {
          delete this.savedData[fieldName];
          this.resetDateFilter(fieldName);
          return;
        }

        this.savedData[fieldName] = {
          dateFrom: res.dateFrom,
          dateTo: res.dateTo
        };

        this.applyDateFilter(
          fieldName,
          res.dateFromFormatted,
          res.dateToFormatted
        );
      });
  }

}
