import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import { UploadService } from '../../services/upload.service';

@Component({
  selector: 'app-upload-btn',
  templateUrl: 'upload-btn.component.html',
  styleUrls: ['upload-btn.component.scss'],
  providers: [ UploadService ]
})
export class UploadBtnComponent implements OnDestroy {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @Input() type: string = 'audio';
  @Input() isDocument: boolean = false;
  @Input() accept: string = 'audio/mp3,audio/wav,audio/m4a,audio/flac';
  @Input() hideDelete: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(public uploadService: UploadService) {}

  onFileChange(event) {
    if (this.type === 'audio') {
      this.onChange.emit({
        type: 'audio',
        files: event.target.files
      });
      this.uploadService.abortUploading();
      this.uploadService.uploadFile(event.target.files[0]);
      this.uploadService.getUploadingProcess().subscribe(res => {
        this.onChange.emit({
          type: 'audio',
          progress: res,
        });
      });

      this.uploadService.getUploadedSong().subscribe(res => {
        this.onChange.emit({
          type: 'audio',
          result: res
        });
      });
      event.target.value = '';
    } else if (this.type === 'document') {
      const groupId = this.getGroupId(event.target.formAction);

      this.uploadService.uploadDocument(event.target.files, groupId)
        .then(res => {
          this.onChange.emit({
            type: 'document',
            files: event.target.files,
            result: res
          });
          event.target.value = '';
        });
    } else {
      this.uploadService.uploadImage(event.target.files)
        .then(res => {
          this.onChange.emit({
            type: 'image',
            files: event.target.files,
            result: res
          });
          event.target.value = '';
        });
    }
  }

  onFileRemove() {
    this.onChange.emit('');
  }

  ngOnDestroy(): void {
    this.uploadService.abortUploading();
  }

  private getGroupId(url: string): string {
    const startSlice = url.indexOf('groups/') + 7;
    const endSlice = url.indexOf('/users');

    return url.slice(startSlice, endSlice);
  }
}
