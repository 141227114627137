import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import {SupportCategoriesService} from '../../categories/support-categories.service';
import {ApiService} from '../../../../services/api.service';

@Component({
  selector: 'app-support-faq-item',
  styleUrls: ['support-faq-item.component.scss'],
  templateUrl: './support-faq-item.component.html'
})
export class SupportFaqItemComponent implements OnInit {
  categories: Array<any> = [];
  formData: FormGroup | any = {};
  defaultData: any = {
    dialogTitle: ''
  };

  constructor(public dialogRef: MatDialogRef<SupportFaqItemComponent>,
              private fb: FormBuilder,
              private api: ApiService,
              private categoriesService: SupportCategoriesService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.defaultData = {
      ...this.defaultData,
      ...this.data
    };
    this.formData = fb.group({
      id: this.data.id,
      question: this.data.question,
      answer: this.data.answer,
      category: this.data.category ? this.data.category.id : null,
    });
  }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    this.categoriesService.getItems().subscribe(
        res => {
          this.categories = res.entities || [];
        },
        error => this.api.errorHandler(error)
    );
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSubmit(): void {
    if (this.formData.invalid) return;
    this.data = {...this.formData.value};
    this.dialogRef.close(this.data);
  }
}
