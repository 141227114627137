import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingService } from './setting.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  formData: any = {};
  form: any = {};

  constructor(public setting: SettingService,
              private notification: NotificationService) {
  }

  ngOnInit() {
    [
      'testimonialEmail',
      'supportEmail',
    ].forEach((dataKey) => {
      this.setting.getItems().subscribe(data => {
        if (data.entities.length) {
          data.entities.forEach((itemData) => {
            if (itemData.key === dataKey) {
              this.formData[dataKey] = itemData;
              this.initFormData(dataKey);
            }
          });
        }
      });

      this.formData[dataKey] = {};
      this.initFormData(dataKey);
    });
  }

  initFormData(dataKey: string) {
    this.form[dataKey] = new FormGroup({
      [dataKey]: new FormControl(this.formData[dataKey].value)
    });
  }

  submitForm(dataKey) {
    if (this.form[dataKey].invalid) return;

    const data = {
      key: dataKey,
      value: this.form[dataKey].get(dataKey).value.trim(),
    };

    this.setting.createOrUpdateItem(data).subscribe(() => {
      this.notification.success('Setting has been successfully saved');
    });
  }

}
