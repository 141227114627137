import {throwError as observableThrowError, Observable, Subject, BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { filter, map, tap } from 'rxjs/operators';
import { UploadFilesStateService } from './upload-files-state.service';

@Injectable()
export class UploadService {
  chunksQuantity;
  chunksQueue;
  file;
  externalUploading: boolean = false;
  uploadingFileName = null;

  private urlPath: string = '/files';
  private _chunkSize = 1024 * 256 * 8;
  private _option: any = {};
  private _progress$: Subject<number>;
  private _uploadedSong$: Subject<any>;
  private uploadAborted: boolean;
  private totalProgress = 0;

  constructor(private http: HttpClient,
              private api: ApiService,
              private notification: NotificationService,
              private uploadFilesState: UploadFilesStateService) {
  }

  public uploadImage(files: Array<any>) {
    const errMessage = 'You should select at least one file.';

    if (files.length && files.length === 1) {


      this.notification.info('Uploading file...', 0);
      return this.uploadSingleFile(files[0]);
    }

    this.notification.error(errMessage);
    return Promise.reject(errMessage);
  }

  public uploadDocument(files: Array<any>, id: string) {
    const errMessage = 'You should select at least one file.';

    if (files.length && files.length === 1) {
      this.notification.info('Uploading file...', 0);
      return this.uploadGroupUsersFile(files[0], id);
    }

    this.notification.error(errMessage);
    return Promise.reject(errMessage);
  }

  public abortUploading(): void {
    if (this._uploadedSong$) {
      this.uploadAborted = true;
      this._uploadedSong$.complete();
      this._progress$.complete();
      this._uploadedSong$ = null;
      this._progress$ = null;
      this.totalProgress = 0;
    }

    this.uploadFilesState.popUploadingFile(this.uploadingFileName);
    this.externalUploading = false;
  }

  uploadFile(file) {
    this._progress$ = new Subject();
    this._uploadedSong$ = new Subject();
    this.uploadAborted = false;

    this.file = file;
    this.chunksQuantity = Math.ceil(file.size / this._chunkSize);
    this.chunksQueue = new Array(this.chunksQuantity).fill(null).map((_, index) => index).reverse();

    this.externalUploading = false;

    this.uploadFilesState.putCountFiles(this.file.name);

    this.sendNext();
  }

  sendNext() {
    if (this.uploadAborted) {
      this.chunksQueue = [];
      return this.notification.error('File uploading aborted.');
    }
    const chunkId = this.chunksQueue.pop();
    const begin = chunkId * this._chunkSize;
    const chunk = this.file.slice(begin, begin + this._chunkSize);
    this.upload(chunk, begin);
  }

  upload(chunk, offset) {
    const fullUrl = `${environment.baseUrl}${this.urlPath}`;
    const op = this.getOptions(offset, chunk.size);
    const fd = new FormData();
    let prevLoadedValue = 0;
    fd.append('file', chunk);

    this.showExternalUploading();

    return this.http.request(new HttpRequest('POST', fullUrl, fd, { ...op, reportProgress: true }))
      .pipe(
        tap((event: any) => {
          if (event.type === 1) {
            this.totalProgress += event.loaded - prevLoadedValue;
            this._progress$.next(100 * this.totalProgress / this.file.size);
            prevLoadedValue = event.loaded;
          }
        }),
        filter(event => event instanceof HttpResponse),
        map((res: HttpResponse<any>) => res.body)
      )
      .subscribe(res => {
          if (!this.chunksQueue.length) {
            this._uploadedSong$.next(res);
            this._progress$.next(100);
            this.abortUploading();
            this.notification.success('File successfully uploaded.');
            return;
          } else {
            this.sendNext();
          }
        },
        err => {
          this.uploadFilesState.popUploadingFile(this.uploadingFileName);

          this.notification.error('File upload error.');
          console.log(err);
        });
  }

  showExternalUploading() {
    if ((this.chunksQuantity > 1 && this.chunksQueue.length === 1)
      || (this.chunksQuantity === 1 && this.chunksQueue.length === 0)
    ) {
      this.externalUploading = true;
    }
  }

  getOptions(offset, chunkSize) {
    if (!this.uploadingFileName) {
      this.uploadingFileName = this.uploadFilesState.genFileName(this.file.name);
      this.uploadFilesState.putUploadingFile(this.uploadingFileName);
    }

    const headers = new HttpHeaders()
      .set('Content-Disposition', `attachment; filename="${encodeURIComponent(this.uploadingFileName)}"`)
      .set('Content-Range', `bytes ${offset}-${offset + chunkSize - 1}/${this.file.size}`)
      .set('Authorization', `Bearer ${this.api.getLocalToken()}`);
    this._option = { headers };

    return this._option;
  }

  getUploadingProcess(): Subject<number> {
    return this._progress$;
  }

  getUploadedSong(): Subject<any> {
    return this._uploadedSong$;
  }

  private post(body: any, options): Observable<any> {
    const fullUrl = `${environment.baseUrl}${this.urlPath}`;

    return this.http.post(fullUrl, body, options);
  }

  private postGroupUsers(body: any, options, id): Observable<any> {
    const fullUrl = `${environment.baseUrl}/groups/${id}/users/file`;
    return this.http.post(fullUrl, body, options);
  }

  private errorHandler(err) {
    if (err.error && err.error.fields) {
      const fields = err.error.fields;
      let delay = 0;
      Object.keys(fields).forEach(key => {
        const upperCaseName = key[0].toUpperCase() + key.slice(1);
        setTimeout(() => {
          this.notification.error(`${upperCaseName}: ${fields[key].errors[0].description}`);
        }, delay);
        delay += 2000;
      });
    } else {
      this.notification.error('File upload failed.');
    }

    return observableThrowError(err);
  }

  private uploadSingleFile(file) {
    const fd = new FormData();
    fd.append('file', file);

    return this.post(fd, this.api.getOptions({}, true))
      .toPromise()
      .then(res => {
        this.notification.success('File successfully uploaded.');
        return res;
      })
      .catch(err => {
        this.errorHandler(err);
        return false;
      });
  }

  private uploadGroupUsersFile(file, id: string) {
    const fd = new FormData();
    fd.append('file', file);

    return this.postGroupUsers(fd, this.api.getOptions({}, true), id)
      .toPromise()
      .then(res => {
        this.notification.success('File successfully uploaded.');
        return res;
      })
      .catch(err => {
        this.errorHandler(err);
        return false;
      });
  }
}
