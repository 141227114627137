import {Component} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DialogService} from '../../services/dialog.service';
import {SoundReportService} from './sound-report.service';
import {MatDialog} from '@angular/material';
import {GridViewClass} from '../../shared/grid-view/grid-view.class';
import {NotificationService} from '../../services/notification.service';
import {SoundsService} from '../sounds/sounds.service';

@Component({
  selector: 'app-sound-report',
  templateUrl: './sound-report.component.html',
  styleUrls: ['./sound-report.component.scss']
})
export class SoundReportComponent extends GridViewClass<SoundReportComponent> {
  public columnNames: Array<string> = [
    'id',
    'firstName',
    'lastName',
    'email',
    'numPlays',
    'longestStreak',
    'currentStreak',
  ];

  constructor(dialogService: DialogService,
              router: Router,
              private routes: ActivatedRoute,
              private dialog: MatDialog,
              private soundReportService: SoundReportService,
              private soundsService: SoundsService,
              private notification: NotificationService) {
    super(dialogService, router);
  }

  getPathId() {
    return (this.routes.params as Params).value.id;
  }

  getData() {
    this.initDefaultOrder();

    const id = this.getPathId();

    if (id) {
      this.queryParams['user-sound'] = id;
    }

    const params = {
      ...this.queryParams
    };

    this.soundReportService.getItems(params).subscribe(data => {
      this.getSoundInfo(data);
      super.setData(data);
    });
  }

  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'id|desc';
    }
  }

  getSoundInfo(data) {
    if (data.entities.length) {
      this.info.sound = {
        id: data.entities[0].soundInfo.id,
        title: data.entities[0].soundInfo.title,
        categoryName: data.entities[0].soundInfo.categoryName,
        numListeners: data.entities[0].soundInfo.numListeners,
        totalTimeListened: data.entities[0].soundInfo.totalTimeListened,
      };
    }
  }

  export() {
    const id = this.getPathId();

    if (id) {
      this.soundsService.exportItems(id).subscribe(res => {
        this.notification.success(`Audio report successfully exported.`);
      });
    }
  }
}
