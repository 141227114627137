import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Constants } from '../../services/constants.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPassForm = new FormGroup({
      email: new FormControl('',
        [Validators.required, Validators.pattern(Constants.emailRegExp)])
    }
  );

  constructor(private auth: AuthService) {
  }

  ngOnInit() {
  }

  getErrorMessage(fieldName = '') {
    const field = this.resetPassForm.controls[fieldName];
    if (field && field.invalid) {
      if (field.errors.required) {
        return 'Field is required';
      }
      if (field.errors.email || field.errors.pattern) {
        return 'Email is invalid';
      }
    }

    return '';
  }

  submit() {
    this.auth.resetPassword(this.resetPassForm.controls['email'].value).subscribe();
  }
}
