import { DialogService } from '../../services/dialog.service';
import { AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

export abstract class GridViewClass<T> implements OnInit, AfterViewInit {
  public info: any = {};
  public items;
  public dataSource = new MatTableDataSource();
  public paginatorConfig: any = {
    length: 0,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
    firstLastButtons: true
  };
  @ViewChild(MatSort, {static: true})
  protected sort: MatSort;
  @ViewChild(MatPaginator, {static: true})
  protected paginator: MatPaginator;
  protected abstract columnNames: Array<string> = [];
  protected sortDir: string;
  protected queryParams: any = {
    'page': this.paginatorConfig.pageIndex + 1,
    'per-page': this.paginatorConfig.pageSize
  };
  protected isShowSpinner = false;

  protected constructor(protected dialogService: DialogService,
                        protected router: Router) {
  }

  ngOnInit() {
    this.getData();
  }

  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange.subscribe(this.sortChange.bind(this));
    }
  }

  public pageChange(event) {
    this.queryParams['per-page'] = event.pageSize;
    this.queryParams['page'] = event.pageIndex + 1;
    this.getData();
  }

  public goToItem(itemId) {
    this.router.navigate(['/integrations', itemId]);
  }

  public applySearch(searchValue: string = '') {
    searchValue = searchValue.trim();
    this.resetPaginator();
    this.queryParams.query = `*${searchValue}*`;
    if (!searchValue) {
      delete this.queryParams.query;
    }
    this.getData();
  }

  public applyDateFilter(fieldName, dateFrom, dateTo) {
    this.resetPaginator();
    this.queryParams[fieldName] = `${dateFrom}<>${dateTo}`;
    this.getData();
  }

  public resetDateFilter(fieldName) {
    this.resetPaginator();
    delete this.queryParams[fieldName];
    this.getData();
  }

  protected sortChange(event) {
    this.sortDir = event.direction;
    if (event.direction) {
      this.queryParams['order-by'] = `${event.active}|${event.direction}`;
    } else {
      delete this.queryParams['order-by'];
    }
    this.getData();
  }

  protected getData() {
    this.isShowSpinner = true;
  }

  protected setData(data) {
    this.items = (data.entities.length) ? data.entities : [];
    this.dataSource.data = [...this.items];
    this.paginatorConfig.length = data.total;
    this.isShowSpinner = false;
  }

  protected resetPaginator() {
    this.queryParams.page = 1;
    this.paginator.pageIndex = 0;
  }
}
