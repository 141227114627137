import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SupportModule } from './support/support.module';

import { CategoriesService } from './categories/categories.service';
import { SoundsService } from './sounds/sounds.service';

import { SignInComponent } from './sign-in/sign-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmationResetPasswordComponent } from './confirmation-reset-password/confirmation-reset-password.component';
import { AccountComponent } from './account/account.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryItemComponent } from './categories/category-item/category-item.component';
import { SoundsComponent } from './sounds/sounds.component';
import { SoundItemComponent } from './sounds/sound-item/sound-item.component';
import { AppDataService } from '../services/app-data.service';
import { UsersService} from './users/users.service';
import { UserItemComponent } from './users/user-item/user-item.component';
import { UsersComponent } from './users/users.component';
import { EventsComponent } from './events/events.component';
import { CloseAccountReasonItemComponent } from './close-account-reasons/close-account-reason-item/close-account-reason-item.component';
import { CloseAccountReasonsService } from './close-account-reasons/close-account-reasons.service';
import { CloseAccountReasonsComponent } from './close-account-reasons/close-account-reasons.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { PageComponent } from './page.component';
import { EventsService } from './events/events.service';
import { SoundReportComponent } from './sound-report/sound-report.component';
import { SoundReportService } from './sound-report/sound-report.service';
import { BackBtnComponent } from './back-btn/back-btn.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingService } from './settings/setting.service';
import { CategoryReportComponent } from './category-report/category-report.component';
import { CategoryReportService } from './category-report/category-report.service';
import { GroupsComponent } from './groups/groups.component';
import { GroupsService } from './groups/groups.service';
import { GroupItemComponent } from './groups/group-item/group-item.component';
import { GroupUsersComponent } from './groups/group-users/group-users.component';
import { GroupUsersService } from './groups/group-users/group-users.service';
import { UserTrialComponent } from './users/user-trial/user-trial.component';
import { GroupImportUsersComponent } from './groups/group-import-users/group-import-users.component';
import { GroupImportErrorsListComponent } from './groups/group-import-errors-list/group-import-errors-list.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SupportModule
  ],
  exports: [
    BackBtnComponent
  ],
  declarations: [
    SignInComponent,
    ResetPasswordComponent,
    ConfirmationResetPasswordComponent,
    AccountComponent,
    CategoriesComponent,
    CategoryItemComponent,
    SoundsComponent,
    SoundItemComponent,
    UsersComponent,
    UserItemComponent,
    EventsComponent,
    CloseAccountReasonsComponent,
    CloseAccountReasonItemComponent,
    DateFilterComponent,
    PageComponent,
    SoundReportComponent,
    BackBtnComponent,
    SettingsComponent,
    CategoryReportComponent,
    GroupsComponent,
    GroupItemComponent,
    GroupUsersComponent,
    UserTrialComponent,
    GroupImportUsersComponent,
    GroupImportErrorsListComponent
  ],
  entryComponents: [
    CategoryItemComponent,
    SoundItemComponent,
    UserItemComponent,
    EventsComponent,
    CloseAccountReasonItemComponent,
    DateFilterComponent,
    SoundReportComponent,
    CategoryReportComponent,
    GroupItemComponent,
    UserTrialComponent,
    GroupImportUsersComponent,
    GroupImportErrorsListComponent
  ],
  providers: [
    UsersService,
    CategoriesService,
    AppDataService,
    SoundsService,
    CloseAccountReasonsService,
    EventsService,
    SoundReportService,
    SettingService,
    CategoryReportService,
    GroupsService,
    GroupUsersService
  ]
})
export class ViewsModule {
}
