import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Constants} from '../../services/constants.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  invalidCredentials: boolean = false;
  isHidePassword = false;
  signInForm = new FormGroup({
    username: new FormControl('',
      [
        Validators.required,
        Validators.pattern(Constants.emailRegExp)
      ]),
    password: new FormControl('',
      [Validators.required])
  });

  constructor(public auth: AuthService) {
  }

  getErrorMessage(fieldName = '') {
    const field = this.signInForm.controls[fieldName];
    if (field && field.invalid) {
      if (field.errors.required) {
        return 'Field is required';
      }
      if (field.errors.email || field.errors.pattern) {
        return 'Email is invalid';
      }
    }

    return '';
  }

  submit() {
    if (this.signInForm.invalid) return;
    this.auth.signIn(this.signInForm.value)
      .subscribe(() => {
      }, () => {
        this.invalidCredentials = true;
      });
  }
}
