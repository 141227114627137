import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-item',
  styleUrls: ['user-item.component.scss'],
  templateUrl: './user-item.component.html',
})

export class UserItemComponent {
  formData: FormGroup | any = {};
  defaultData: any = { dialogTitle: '' };

  get firstNameErrors() {
    return this.formData.get('firstName').errors;
  }

  get firstNameTouched() {
    return this.formData.get('firstName').touched;
  }

  get firstNameMinLength() {
    return this.formData.get('firstName').errors.minlength;
  }

  get lastNameErrors() {
    return this.formData.get('lastName').errors;
  }

  get lastNameTouched() {
    return this.formData.get('lastName').touched;
  }

  get lastNameMinLength() {
    return this.formData.get('lastName').errors.minlength;
  }

  constructor(
    public dialogRef: MatDialogRef<UserItemComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.defaultData = {
      ...this.defaultData,
      ...this.data
    };
    this.formData = fb.group({
      id: this.data.id,
      firstName: [this.data.firstName, [Validators.minLength(2)]],
      lastName: [this.data.lastName, [Validators.minLength(2)]],
      email: [this.data.email, [Validators.required,  Validators.email]],
      country: this.data.country,
      birthday: this.data.birthday && new Date(this.data.birthday)
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSubmit(): void {
    if (this.formData.invalid) {
      return;
    }

    const dPipe = new DatePipe('en-Us');
    this.data = {
      ...this.formData.value,
      birthday: dPipe.transform(this.data.birthday, 'yyyy-MM-dd')
    };

    this.dialogRef.close(this.data);
  }
}
