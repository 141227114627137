import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MillisecPipe } from './millisec.pipe';
import { DurationPipe } from './duration.pipe';

@NgModule({
  declarations: [
    MillisecPipe,
    DurationPipe
  ],
  exports: [
    MillisecPipe,
    DurationPipe
  ],
  providers: [
    DatePipe
  ]
})

export class PipesModule {
}
