import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-group-import-errors-list',
  templateUrl: './group-import-errors-list.component.html',
  styleUrls: ['group-import-errors-list.scss']
})

export class GroupImportErrorsListComponent {
  constructor(
    public dialogRef: MatDialogRef<GroupImportErrorsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  get alreadyPresentList(): string[] {
    return this.data.errorsList.alreadyPresent.emails;
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
