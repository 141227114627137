import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boolean-mark',
  templateUrl: 'boolean-mark.component.html',
  styleUrls: ['boolean-mark.component.scss']
})
export class BooleanMarkComponent {
  @Input('value') value: boolean;
}
