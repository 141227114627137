import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appObjectFit]'
})
export class ObjectFitDirective {

  constructor(elem: ElementRef) {
    const element = elem.nativeElement;

    if (element && element.tagName === 'IMG') {
      element.style.display = 'none';
      element.addEventListener('load', () => {
        this.setDimensions(element);
        this.setCenter(element);
      });
    }
  }

  setDimensions(img) {
    if (img.naturalHeight >= img.naturalWidth) {
      img.style.width = '100%';
      img.style.height = 'auto';
    }
    if (img.naturalHeight < img.naturalWidth) {
      img.style.height = '100%';
      img.style.width = 'auto';
    }
  }

  setCenter(img) {
    img.parentElement.style.position = 'relative';
    img.style.cssText = `${img.style.cssText}
      position: absolute;
      display: initial;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    `;
  }

}
