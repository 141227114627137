import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { CategoriesService } from '../../categories/categories.service';
import { UploadService } from '../../../services/upload.service';
import { UploadFilesStateService } from '../../../services/upload-files-state.service';

@Component({
  selector: 'app-sound-item',
  styleUrls: ['sound-item.component.scss'],
  templateUrl: './sound-item.component.html'
})
export class SoundItemComponent implements OnInit {
  categories: Array<any> = [];
  formData: FormGroup | any = {};
  defaultData: any = {
    dialogTitle: ''
  };
  voiceProgress: number;
  backgroundProgress: number;

  constructor(public dialogRef: MatDialogRef<SoundItemComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private api: ApiService,
              private categoriesService: CategoriesService,
              private uploadFilesState: UploadFilesStateService) {
    this.defaultData = {
      ...this.defaultData,
      ...this.data
    };

    this.formData = fb.group({
      id: this.data.id,
      title: this.data.title,
      category: this.data.category ? this.data.category.id : null,
      availableForDemo: this.data.availableForDemo,
      duration: this.data.duration,
      description: this.data.description,
    });
  }

  ngOnInit() {
    this.getCategories();
    this.uploadFilesState.clearUploadingFiles();
  }

  getCategories() {
    this.categoriesService.getItems().subscribe(
      res => {
        this.categories = res.entities || [];
      },
      error => this.api.errorHandler(error)
    );
  }

  coverChanged(res) {
    if (res.result) {
      this.defaultData.coverPath = res.result.path;
      this.formData.setControl('cover', this.fb.control(res.result.id));
    }
  }

  voiceChanged(res) {
    if (res.files) {
      const sound = res.files[0];
      this.defaultData.voice = sound.name;
      const o = URL.createObjectURL(sound);
      const audio = new Audio();
      audio.src = o;
      audio.load();
      audio.oncanplaythrough = () => {
        this.defaultData.duration = audio.duration;
        this.formData.get('duration').setValue(this.defaultData.duration);
      };
    }

    this.voiceProgress = res.progress;

    if (res.result) {
      this.formData.setControl('voice', this.fb.control(res.result.id));
    }
  }

  backgroundChanged(res) {
    if (res.files) {
      const sound = res.files[0];
      this.defaultData.background = sound.name;
      const url = URL.createObjectURL(sound);
      const audio = new Audio();
      audio.src = url;
    }

    this.backgroundProgress = res.progress;

    if (res.result) {
      this.formData.setControl('background', this.fb.control(res.result.id));
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onSubmit(): void {
    if (this.formData.invalid) {
      return;
    }
    this.data = { ...this.formData.value };
    this.dialogRef.close(this.data);
  }
}
