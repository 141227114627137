
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent {
  @Output() search: EventEmitter<any> = new EventEmitter();
  public searchSubject: Subject<any> = new Subject();

  constructor() {
    this.searchSubject.pipe(
      distinctUntilChanged(),
      debounceTime(300),)
      .subscribe(value => this.search.emit(value));
  }

  clearSearch(inputElement) {
    inputElement.value = '';
    this.search.emit(inputElement.value);
  }
}
