import { Component } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { UsersService } from './users.service';
import { MatDialog } from '@angular/material';
import { UserItemComponent } from './user-item/user-item.component';
import { GridViewClass } from '../../shared/grid-view/grid-view.class';
import { AccountService } from '../../services/account.service';
import { NotificationService } from '../../services/notification.service';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { UserTrialComponent } from './user-trial/user-trial.component';

@Component({
  selector: 'app-reports',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends GridViewClass<UsersComponent> {
  public columnNames: Array<string> = [
    'id',
    'firstName',
    'lastName',
    'email',
    'createdAt',
    'lastAction',
    'os',
    'enabled',
    'subscriptionExpiredAt',
    'trialEndedAt',
    'actions'
  ];

  public savedData: Params = {};
  public now = Date.now();

  constructor(dialogService: DialogService,
              router: Router,
              private dialog: MatDialog,
              private usersService: UsersService,
              public account: AccountService,
              private notification: NotificationService) {
    super(dialogService, router);
  }

  getData() {
    this.initDefaultOrder();

    const params = {
      ...this.queryParams
    };

    this.usersService.getItems(params).subscribe(data => {
      super.setData(data);
    });
  }

  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'createdAt|desc';
    }
  }

  deleteUser(itemData) {
    this.dialogService.open({
      data: {
        title: 'Are you sure you want to delete this user?'
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.usersService.removeItem(itemData.id)
          .subscribe(_ => {
            this.getData();
          });
      }
    });
  }

  openEditModal(data: any = {}) {
    const dialogRef = this.dialog.open(UserItemComponent, {
      data,
      width: '500px',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        if (res.delete) {
          return this.usersService.removeItem(res.id)
            .subscribe(() => {
              this.getData();
            });
        }
        if (res.id) {
          this.usersService.updateItem(res)
            .subscribe(() => {
              this.getData();
            });
        } else {
          this.usersService.createItem(res)
            .subscribe(() => {
              this.getData();
            });
        }
      });
  }

  openEditFreeTrialModal(data: any = {}) {
    const dialogRef = this.dialog.open(UserTrialComponent, {
      data,
      width: '500px',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.usersService.updateItemTrial(res)
          .subscribe(() => {
            this.getData();
          });
      });
  }

  toggleActiveState(itemData) {
    this.dialogService.open({
      data: {
        title: `Are you sure you want to ${!itemData.enabled ? 'activate' : 'deactivate'} this user?`
      }
    }).subscribe(res => {
      if (res && res.accept) {
        this.usersService.blockItem(
          itemData.id,
          !itemData.enabled
        ).subscribe(() => {
          this.getData();
        });
      }
    });
  }

  dateFilter(fieldName) {
    const dialogRef = this.dialog.open(DateFilterComponent, {
      data: {
        dateFrom: this.savedData[fieldName] && this.savedData[fieldName].dateFrom,
        dateTo: this.savedData[fieldName] && this.savedData[fieldName].dateTo,
      },
      width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }

        if (res.reset) {
          delete this.savedData[fieldName];
          this.resetDateFilter(fieldName);
          return;
        }

        this.savedData[fieldName] = {
          dateFrom: res.dateFrom,
          dateTo: res.dateTo
        };

        this.applyDateFilter(
          fieldName,
          res.dateFromFormatted,
          res.dateToFormatted
        );
      });
  }

  export(id?: number) {
    this.usersService.exportItems(id, this.queryParams).subscribe(res => {
      this.notification.success(`User${id ? '' : 's'} successfully exported.`);
    });
  }

  openModal(data: any = {}) {
    const dialogRef = this.dialog.open(UserItemComponent, { width: '500px', data, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      if (res.id) {
        delete res.accept;
        this.usersService.createUserWithGeneratedPassword(res)
          .subscribe(() => {
            this.getData();
          });
      } else {
        this.usersService.createUserWithGeneratedPassword(res)
          .subscribe(() => {
            this.getData();
          });
      }
    });
  }

  public checkTrialEnded(user): boolean {
    const trialEndedAt = new Date(user.trialEndedAt).getTime();

    if (this.now > trialEndedAt) {
      return false;
    }
    return true;
  }

  public checkSubscriptionExpired(user): boolean {
    const subscriptionExpiredAt = new Date(user.subscriptionExpiredAt).getTime();

    if (this.now > subscriptionExpiredAt) {
      return false;
    }
    return true;
  }
}
