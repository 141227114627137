import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { CategoryReportService } from './category-report.service';
import { MatDialog } from '@angular/material';
import { GridViewClass } from '../../shared/grid-view/grid-view.class';
import { NotificationService } from '../../services/notification.service';
import { CategoriesService } from '../categories/categories.service';

@Component({
  selector: 'app-category-report',
  templateUrl: './category-report.component.html',
  styleUrls: ['./category-report.component.scss']
})
export class CategoryReportComponent extends GridViewClass<CategoryReportComponent> {
  public columnNames: Array<string> = [
    'id',
    'title',
    'createdAt',
    'avgRating',
    'numListeners',
    'totalTimeListened',
  ];

  constructor(dialogService: DialogService,
              router: Router,
              private routes: ActivatedRoute,
              private dialog: MatDialog,
              private categoriesService: CategoriesService,
              private categoryReportService: CategoryReportService,
              private notification: NotificationService) {
    super(dialogService, router);
  }

  getPathId() {
    return (this.routes.params as Params).value.id;
  }

  getData() {
    this.initDefaultOrder();

    const id = this.getPathId();

    if (id) {
      this.queryParams['category'] = id;
      this.queryParams['expand'] = 'category';
    }

    const params = {
      ...this.queryParams
    };
    this.categoryReportService.getItems(params).subscribe(data => {
      this.getCategoryInfo(data);
      super.setData(data);
    });
  }

  initDefaultOrder() {
    if (!('order-by' in this.queryParams)) {
      this.queryParams['order-by'] = 'createdAt|desc';
    }
  }

  getCategoryInfo(data) {
    if (data.entities.length) {
      this.info.category = {
        id: data.entities[0].category.id,
        title: data.entities[0].category.name
      };
    }
  }

  export() {
    const id = this.getPathId();

    if (id) {
      this.categoriesService.exportItems(id, this.queryParams).subscribe(res => {
        this.notification.success(`Category report successfully exported.`);
      });
    }
  }
}
