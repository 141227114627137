import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-close-account-reason-item',
  styleUrls: ['close-account-reason-item.component.scss'],
  templateUrl: './close-account-reason-item.component.html'
})
export class CloseAccountReasonItemComponent {
  formData: FormGroup | any = {};
  defaultData: any = {
    dialogTitle: ''
  };

  constructor(public dialogRef: MatDialogRef<CloseAccountReasonItemComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.defaultData = {
      ...this.defaultData,
      ...this.data
    };
    this.formData = fb.group({
      id: this.data.id,
      title: this.data.title,
      description: this.data.description,
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onDelete(): void {
    this.dialogRef.close({id: this.formData.value.id, delete: true});
  }

  onSubmit(): void {
    if (this.formData.invalid) return;
    this.data = {...this.formData.value};
    this.dialogRef.close(this.data);
  }
}
