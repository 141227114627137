import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { MatNativeDateModule } from '@angular/material';

import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';

import { DialogService } from '../services/dialog.service';

import { DialogComponent } from './dialog/dialog.component';
import { BooleanMarkComponent } from './boolean-mark/boolean-mark.component';
import { ActionsBtnComponent } from './actions-btn/actions-btn.component';
import { UploadBtnComponent } from './upload-btn/upload-btn.component';
import { SearchComponent } from './search/search.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DatePickerModule } from './date-picker-format/date-picker.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MatNativeDateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    DatePickerModule
  ],
  declarations: [
    DialogComponent,
    BooleanMarkComponent,
    ActionsBtnComponent,
    UploadBtnComponent,
    SearchComponent
  ],
  exports: [
    DirectivesModule,
    MaterialModule,
    MatNativeDateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PipesModule,
    DialogComponent,
    BooleanMarkComponent,
    ActionsBtnComponent,
    UploadBtnComponent,
    SearchComponent,
    DatePickerModule
  ],
  providers: [
    DialogService,
  ],
  entryComponents: [
    DialogComponent,
  ]
})

export class SharedModule {
}
